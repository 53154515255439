import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import CheckBox from "../CheckBox";
import TimeDropDown from "../TimeDropDown";
import { useDetectClickOutside } from "react-detect-click-outside";
import { ReactSVG } from "react-svg";
import Globe from "../../../../../assets/images/globe.svg";
import { EST_DATE_OPTIONS } from '../../../../../constants/index.js';
import moment from "moment";
import ShabbatInfoModal from "components/ShabbatBanner/shabbatInfoModal";
import { Button } from "antd";

const ScheduleForLater = ({
  scheduleJobTime,
  setScheduleJobTime,
  showSpinner,
  job,
  user,
  scheduleTimeInLocalTimezone,
  setScheduleTimeInLocalTimezone,
  setIsShabbatTime
}) => {
  const [value, setValue] = useState(scheduleJobTime.date);
  const [compareValue, setCompareValue] = useState();
  const [calendarValue, setCalendarValue] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [counter, setCounter] = useState(0);
  const [shabbatTimes, setShabbatTimes] = useState(null);
  const [showShabbatWarning, setShowShabbatWarning] = useState(false);
  const [showShabbatModal, setShowShabbatModal] = useState(false);

  const handelCalender = (e) => {
    setValue(e);
    setScheduleJobTime((prevState) => ({ ...prevState, date: e }));
  };

  const onOutsideClick = () => {
    if (counter % 2 !== 0) setCounter(0);
    if (showCalendar && counter % 2 === 0) setShowCalendar(false);
  };

  const ref = useDetectClickOutside({ onTriggered: onOutsideClick });

  let todayDate = new Date();
  let todaydd = `${todayDate.getFullYear()}${todayDate.getMonth()}${todayDate.getDate()}`;

  useEffect(() => {
    const hours24 = scheduleJobTime.durationType === "AM" ? scheduleJobTime.hours % 12 : (scheduleJobTime.hours % 12) + 12;
    const minutes = scheduleJobTime.minutes;
    let selectedScheduleDateAndTime = scheduleJobTime.date;
    selectedScheduleDateAndTime = scheduleJobTime.date.setHours(hours24);
    selectedScheduleDateAndTime = new Date(scheduleJobTime.date).setMinutes(minutes);

    setScheduleTimeInLocalTimezone(new Date(selectedScheduleDateAndTime));
    checkIfShabbatTime(new Date(selectedScheduleDateAndTime));
  }, [scheduleJobTime]);

  useEffect(() => {
    let todayCalenderValue = `${todayDate.getFullYear()}${value.getMonth()}${value.getDate()}`;
    setCompareValue(todayCalenderValue);
    setCalendarValue(
      `${value.toString().split(" ")[1]} ${value.toString().split(" ")[2]}`
    );
  }, [value]);

  const formatShabbatTime = (date) => {
    return moment(date).format('dddd, MMMM D [at] h:mm A');
  };

  const checkIfShabbatTime = async (selectedTime) => {
    try {
      const response = await fetch(`https://www.hebcal.com/shabbat?cfg=json&tzid=America/New_York&city=NYC`);
      const data = await response.json();

      const candleLighting = data.items.find(item => item.category === 'candles');
      const havdalah = data.items.find(item => item.category === 'havdalah');

      if (candleLighting && havdalah) {
        const selectedDateTime = moment(selectedTime);
        const shabbatStart = moment(candleLighting.date);
        const shabbatEnd = moment(havdalah.date);

        const isShabbat = selectedDateTime.isBetween(shabbatStart, shabbatEnd);
        setIsShabbatTime(isShabbat);

        if (isShabbat) {
          setShabbatTimes({
            start: candleLighting.date,
            end: havdalah.date
          });
          setShowShabbatWarning(true);
        } else {
          setShowShabbatWarning(false);
        }
      }
    } catch (error) {
      console.error('Error checking Shabbat schedule:', error);
      setIsShabbatTime(false);
    }
  };

  let minArray = ["00", "15", "30", "45"];
  let hourArray = Array.from({ length: 12 }, (_, i) => String(i + 1));

  const handleTodayClick = () => {
    setShowCalendar(!showCalendar);
    setCounter(counter + 1);
    if (counter % 2 !== 0) setCounter(1);
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="flex-wrap" style={{ width: "100%", maxWidth: "600px", display: "flex", justifyContent: "center" }}>
        <div className="d-flex justify-content-start flex-wrap mb-20">
          {/* Date selection */}
          <div>
            <div>
              <label className="date-label-div">Date:</label>
            </div>
            <div className="cursor-pointer">
              <div
                className={`${!showCalendar === false
                  ? "today-div-true d-flex justify-content-start align-items-center"
                  : "today-div d-flex justify-content-start align-items-center"
                  }`}
                onClick={handleTodayClick}
              >
                <span className="date-value">
                  {compareValue === todaydd ? "Today" : calendarValue}
                </span>
              </div>
            </div>
          </div>

          {/* Time selection */}
          <div className="d-flex mt-10-max-width-600 mb-30-max-width-600">
            <div>
              <div>
                <label className="date-label-div">Time:</label>
              </div>
              <div className="time-div d-flex justify-content-center align-items-center">
                <TimeDropDown
                  dropdownValues={hourArray}
                  name={"hour"}
                  scheduleJobTime={scheduleJobTime}
                  setScheduleJobTime={setScheduleJobTime}
                  showSpinner={showSpinner}
                />
                <span className="colon-dropdown">:</span>
                <TimeDropDown
                  dropdownValues={minArray}
                  name={"minutes"}
                  scheduleJobTime={scheduleJobTime}
                  setScheduleJobTime={setScheduleJobTime}
                  showSpinner={showSpinner}
                />
              </div>
            </div>
            <div className="check-box">
              <CheckBox
                scheduleJobTime={scheduleJobTime}
                setScheduleJobTime={setScheduleJobTime}
                showSpinner={showSpinner}
              />
            </div>
          </div>
        </div>

        {/* Time zone display */}
        <div className="d-flex align-items-center gap-8">
          <ReactSVG src={Globe} />
          <div className="d-flex flex-column">
            <span className="localTime text-left white-space-break">
              {`Eastern Standard Time (EST): ` + new Date(scheduleTimeInLocalTimezone).toLocaleTimeString("en-US", EST_DATE_OPTIONS)}
            </span>
          </div>
        </div>

        {showShabbatWarning && shabbatTimes && (
          <div className="warning-message" style={{ maxWidth: '655px' }}>
            <strong>Please select a different time outside of Shabbat.</strong>
            <p className="text-sm text-yellow-700" style={{ maxWidth: '655px', textWrap: 'wrap' }}>
              The time you selected falls during Shabbat, a time traditionally reserved for rest and reflection.
              We are closed during Shabbat which begins this week at{' '}
              <strong>{formatShabbatTime(shabbatTimes.start)}</strong> and ends at{' '}
              <strong>{formatShabbatTime(shabbatTimes.end)}</strong>. {'\n'}
              <a onClick={() => setShowShabbatModal(true)} className="text-blue-600">Learn more about Shabbat</a>
            </p>
          </div>
        )}

        <ShabbatInfoModal
          isVisible={showShabbatModal}
          onClose={() => setShowShabbatModal(false)}
          showReminderSection={false}
        />
        {/* Calendar */}
        {showCalendar && (
          <div className="calendar-container" ref={ref}>
            <Calendar
              tileDisabled={({ date }) => [0, 6].includes(date.getDay())}
              tileClassName={({ date, view }) => {
                if (view === "month" && ![0, 6].includes(date.getDay())) {
                  return "allowed-date";
                }
                return "disabled-date";
              }}
              onChange={handelCalender}
              value={value}
              maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))}
              minDate={new Date()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduleForLater;