import React, { useState, useEffect } from 'react';
import { Button, Modal, Checkbox, Form } from 'antd';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useShabbat } from 'context/shabbatContext';
import { useAuth } from 'context/authContext';
import * as CustomerApi from "../../api/customers.api";
import * as TechnicianApi from "../../api/technician.api";
import { openNotificationWithIcon } from 'utils';
import { isMobile } from 'react-device-detect';
import ShabbatInfoModal from './shabbatInfoModal';

const ShabbatBanner = () => {
  const { isShabbat, shabbatTimes, getRemainingTime } = useShabbat();
  const [showModal, setShowModal] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isShabbat) return;

    const timerInterval = setInterval(() => {
      setTimeRemaining(getRemainingTime());
    }, 1000);

    // Auto show modal on Shabbat start
    const lastShownDate = localStorage.getItem('shabbatModalLastShown');
    const today = new Date().toISOString().split('T')[0];
    if (lastShownDate !== today) {
      setShowModal(true);
      localStorage.setItem('shabbatModalLastShown', today);
    }

    return () => clearInterval(timerInterval);
  }, [isShabbat, getRemainingTime]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleReminderSubmit = async (values) => {
    console.log('Reminder values:', values);
    setLoading(true);
    try {
      if (user?.userType === 'customer') {
        await CustomerApi.updateCustomer(user?.customer.id, {
          needShabbatEndNotificationEmail: values.notifications.includes('email'),
          needShabbatStartNotificationSMS: values.notifications.includes('sms'),
        });
      } else {
        await TechnicianApi.updateTechnician(user?.technician.id, {
          needShabbosNotificationEmail: values.notifications.includes('email'),
          needShabbosNotificationSMS: values.notifications.includes('sms'),
        });
      }

      form.resetFields();
      openNotificationWithIcon('success', 'Reminder Set', 'We will notify you when we are back.');
    } catch (error) {
      openNotificationWithIcon('error', 'Error', 'Failed to set reminder preferences.');
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  if (!isShabbat) return null;

  return (
    <>
      <div style={{ marginTop: isMobile ? 0 : 36 }}>
        <BannerWrapper>
          <BannerContent>
            <InfoSection>
              <InfoCircleOutlined style={{ color: '#FFF8E1', fontSize: 16, flexShrink: 0 }} />
              <BannerText>
                Geeker.co is <strong>currently paused</strong> for Shabbat until{' '}
                <strong>
                  {shabbatTimes.endTimeFormatted.day}, {shabbatTimes.endTimeFormatted.time}
                </strong>{' '}
                EST. 🕯️🕯️ We reopen in <strong>{timeRemaining}</strong>.{' '}
                <LearnMoreButton
                  type="primary"
                  onClick={() => setShowModal(true)}
                >
                  Find out why & more
                </LearnMoreButton>
              </BannerText>
            </InfoSection>
          </BannerContent>
          <ShabbatInfoModal
            isVisible={showModal}
            onClose={handleModalClose}
            showReminderSection={true}
            shabbatTimes={shabbatTimes}
            timeRemaining={timeRemaining}
            onReminderSubmit={handleReminderSubmit}
            loading={loading}
          />
        </BannerWrapper>
      </div>
    </>
  );
}

const ModalContent = styled.div`
  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .quote {
    font-style: italic;
    padding: 16px;
    background: #f8f9fa;
    border-left: 4px solid #15b1b0;
    margin: 16px 0;
  }

  .reminder-section {
    margin-top: 24px;
    padding: 16px;
    background: #fafafa;
    border-radius: 8px;
  }

  .links-section {
    margin-top: 24px;
    
    .link-title {
      font-weight: 600;
      margin-bottom: 8px;
    }
    
    .links-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 12px;
    }
  }
`;

const BannerText = styled.span`
  strong {
    font-weight: 600;
    color: #fff;
  }
  color: rgba(255, 255, 255, 0.9);
`;

const VideoLinksSection = styled.div`
  margin-top: 24px;
  
  .links-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .resources-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
    margin-top: 16px;
  }
`;

const ResourceCard = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 12px;
  border-radius: 8px;
  transition: all 0.2s ease;
  
  &:hover {
    background: #f5f5f5;
    transform: translateY(-2px);
  }
  
  img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  
  span {
    color: #15b1b0;
    font-weight: 500;
    text-align: center;
  }
`;

const ReminderCheckboxes = styled.div`
  .ant-form-item {
    margin-bottom: 12px;
  }
  
  .ant-checkbox-wrapper {
    margin-right: 24px;
  }
`;

const BannerWrapper = styled.div`
  background: linear-gradient(122deg, #006565 0%, #15b1b0 100%);
  width: 100%;
  padding: 8px 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  @media (min-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
`;

const BannerContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 0;
  }
`;

const InfoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-width: 0;
  
  @media (max-width: 768px) {
    justify-content: flex-start;
    margin-left: 4px;
  }
`;

const LearnMoreButton = styled(Button)`
  background: rgba(255, 248, 225, 0.2) !important;
  border: none !important;
  height: 28px !important;
  padding: 0 16px !important;
  margin-left: 8px !important;
  font-size: 14px !important;
  color: #ffffff !important;
  flex-shrink: 0;
  
  &:hover {
    background: rgba(255, 248, 225, 0.3) !important;
  }

  &:active {
    transform: scale(0.98);
    transition: transform 0.1s;
  }

  @media (max-width: 768px) {
    margin-left: 4px !important;
    padding: 0 8px !important;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
  }
  
  .ant-modal-header {
    padding: 16px 24px;
    border-bottom: none;
    background: #15b1b0;
    color: white;
  }
  
  .modal-title {
    display: flex !important;
    align-items: center !important;
    gap: 12px !important;
    font-size: 19px;
    font-weight: 700;
    color: white;
  }

  .ant-modal-title {
    color: white;
  }
  
  .ant-modal-body {
    padding: 24px;
  }
  
  .ant-modal-footer {
    border-top: none;
    padding: 0 24px 24px;
  }
  
  .modal-text {
    color: #2c3e50;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 16px;
  }

  .modal-close-btn {
    background: #15b1b0 !important;
    &:hover {
      background: #006565 !important;
    }
    &:active {
      transform: scale(0.98);
    }
  }
`;

export default ShabbatBanner;