import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import moment from 'moment';

const ShabbatContext = createContext();

export const useShabbat = () => {
  const context = useContext(ShabbatContext);
  if (!context) {
    throw new Error('useShabbat must be used within a ShabbatProvider');
  }
  return context;
};

let shabbatCache = {
  isShabbat: false,
  startTime: null,
  endTime: null,
  lastFetch: null,
  startTimeFormatted: {
    day: '',
    time: ''
  },
  endTimeFormatted: {
    day: '',
    time: ''
  }
};

export default function ShabbatProvider({ children }) {
  const [isShabbat, setIsShabbat] = useState(false);
  const [shabbatTimes, setShabbatTimes] = useState({
    startTime: null,
    endTime: null,
    startTimeFormatted: { day: '', time: '' },
    endTimeFormatted: { day: '', time: '' }
  });
  const [loading, setLoading] = useState(true);

  const checkAndUpdateShabbatStatus = useCallback(() => {
    const now = moment();
    if (shabbatCache.endTime && now.isAfter(shabbatCache.endTime)) {
      setIsShabbat(false);
      shabbatCache = {
        isShabbat: false,
        startTime: null,
        endTime: null,
        lastFetch: null,
        startTimeFormatted: { day: '', time: '' },
        endTimeFormatted: { day: '', time: '' }
      };

      return true;
    }
    return false;
  }, []);

  const getShabbatStatus = async () => {
    try {
      const now = moment();

      // First check if Shabbat has ended
      if (checkAndUpdateShabbatStatus()) {
        setLoading(false);
        return;
      }

      // Use cache if valid
      if (
        shabbatCache.lastFetch &&
        now.diff(shabbatCache.lastFetch, 'seconds') < 60 &&
        shabbatCache.startTime &&
        shabbatCache.endTime
      ) {
        setIsShabbat(now.isBetween(shabbatCache.startTime, shabbatCache.endTime));
        setShabbatTimes({
          startTime: shabbatCache.startTime,
          endTime: shabbatCache.endTime,
          startTimeFormatted: shabbatCache.startTimeFormatted,
          endTimeFormatted: shabbatCache.endTimeFormatted
        });
        return;
      }

      const response = await fetch(
        'https://www.hebcal.com/shabbat?cfg=json&tzid=America/New_York&city=NYC'
      );
      const data = await response.json();

      const candleLighting = data.items.find(item => item.category === "candles");
      const havdalah = data.items.find(item => item.category === "havdalah");

      if (!candleLighting || !havdalah) {
        setIsShabbat(false);
        return;
      }

      const startTime = moment(candleLighting.date);
      const endTime = moment(havdalah.date);
      const isCurrentlyShabbat = now.isBetween(startTime, endTime);

      // Update cache with both start and end time formatting
      shabbatCache = {
        isShabbat: isCurrentlyShabbat,
        startTime,
        endTime,
        lastFetch: now,
        startTimeFormatted: {
          day: startTime.format('dddd'),
          time: startTime.format('h:mm A')
        },
        endTimeFormatted: {
          day: endTime.format('dddd'),
          time: endTime.format('h:mm A')
        }
      };

      setIsShabbat(isCurrentlyShabbat);
      setShabbatTimes({
        startTime,
        endTime,
        startTimeFormatted: shabbatCache.startTimeFormatted,
        endTimeFormatted: shabbatCache.endTimeFormatted
      });

    } catch (error) {
      console.error('Error checking Shabbat status:', error);
      setIsShabbat(false);
    } finally {
      setLoading(false);
    }
  };

  const getRemainingTime = useCallback(() => {
    if (!shabbatTimes.endTime) return '';

    const now = moment();
    const duration = moment.duration(moment(shabbatTimes.endTime).diff(now));

    if (duration.asSeconds() <= 0) {
      checkAndUpdateShabbatStatus();
      return '';
    }

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }, [shabbatTimes.endTime, checkAndUpdateShabbatStatus]);

  useEffect(() => {
    getShabbatStatus();

    // Check status every minute
    const statusInterval = setInterval(getShabbatStatus, 60000);

    // Check remaining time more frequently (every second)
    const timerInterval = setInterval(() => {
      if (isShabbat) {
        checkAndUpdateShabbatStatus();
      }
    }, 1000);

    return () => {
      clearInterval(statusInterval);
      clearInterval(timerInterval);
    };
  }, [checkAndUpdateShabbatStatus, isShabbat]);

  return (
    <ShabbatContext.Provider
      value={{
        isShabbat,
        shabbatTimes,
        loading,
        getRemainingTime,
        refresh: getShabbatStatus
      }}
    >
      {children}
    </ShabbatContext.Provider>
  );
}