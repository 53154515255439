import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import React from 'react'
import './style.css'
import { useUser } from 'context/useContext'

const ScheduleJobModal = ({ openScheduleModal, setOpenScheduleModal, scheduleForLater, isFromMobile, handelScheduleForLater, isDisabled, isRescheduled }) => {

    const handleClose = () => {
        setOpenScheduleModal(false);
    }
    const userData = useUser()

    return (
        <>
            <div>
                <Modal
                    open={openScheduleModal}
                    onClose={() => { setOpenScheduleModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="outer-box-modal1">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} className="close-icon-btn" style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="text-div mg-b-10">
                            <div className="end-the-job-div">
                                {(userData?.user?.roles[0] !== "owner" && userData?.user?.roles[0] !== "admin") ? (<>
                                    <span className="close-icon-btn-text font-nova">
                                        Please make sure to attend your scheduled meeting (note that once a schedule is accepted, your company may incur a small fee for no-shows or last-minute cancellations, so we can keep our technicians happy)
                                    </span>
                                </>) : (<>
                                    {isRescheduled && isFromMobile ?
                                        <span className="close-icon-btn-text font-nova">Please note, we offer free reschedule up to 3 hours before your meeting. However your scheduled job is less than 3 hours away. If you reschedule now, a $24.99 fee will apply</span>
                                        : <div style={{
                                            padding: '20px',
                                            border: '1px solid #ccc',
                                            borderRadius: '8px',
                                            backgroundColor: '#f9f9f9',
                                            maxWidth: '600px',
                                            margin: '0 auto',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <strong style={{ fontSize: '18px', color: '#333' }}>Off-Peak Schedule!</strong>
                                            </div>
                                            <span style={{
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                color: '#555',
                                                lineHeight: '1.6',
                                                display: 'block',
                                                marginTop: '10px',
                                            }}>
                                                You are welcome to schedule with us at any time, but it may take longer to get help outside of business hours.
                                                <br style={{ marginBottom: '10px' }} />
                                                Please note our $24.99 'no-show' fee as a courtesy to our technicians. You can change or cancel up to 2 hours before your appointment.
                                                If you have an emergency, feel free to contact customer care during business hours.
                                            </span>
                                        </div>
                                    }
                                </>)}
                            </div>

                        </div>

                        <div className="d-flex justify-content-end buttons-container mg-b-10 ">
                            <div className="mg-b-10">
                                <Stack className='margin-right-16px' spacing={2} direction="row">
                                    <Button variant="outlined" className="Outlined-btn-join" onClick={() => { setOpenScheduleModal(false) }}>
                                        <span className="join-btn-css">Don’t schedule</span>
                                    </Button>
                                </Stack>
                            </div>

                            <div className="mg-b-10">
                                <Stack spacing={2} direction="row">
                                    <Button variant="outlined" className="contained-end-btn" onClick={isFromMobile ? handelScheduleForLater : scheduleForLater} disabled={isDisabled}>
                                        {isDisabled ? <CircularProgress className='spin-css' /> :
                                            <span className="join-btn-css color-white" >Schedule</span>
                                        }
                                    </Button>
                                </Stack>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default ScheduleJobModal
