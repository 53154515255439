import Button from "@mui/material/Button";
import Select from "react-select";
import { Spin } from "antd";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { isMobile } from "react-device-detect";
import PhoneInput from "react-phone-input-2";
import { ReactSVG } from "react-svg";
import { checkPendingStatusOfASAP, openNotificationWithIcon } from "utils";
import * as CustomerApi from "../../../../../api/customers.api";
import * as JobApi from "../../../../../api/job.api";
import { useJob } from "../../../../../context/jobContext";
import * as JobCycleApi from "../../../../../api/jobCycle.api";
import * as UserApi from "../../../../../api/users.api";
import * as TechnicianApi from "../../../../../api/technician.api";
import calanderLogo from "../../../../../assets/images/calendar.svg";
import { JobTags, JOB_STATUS,GEEKER_MEMBER_FIRST_JOB } from "../../../../../constants";
import { Modal } from "antd";
import {
  checkCustomerHaveSubscriptionMinutes,
  checkOwnerStripeId,
  ifJobStatusIsNotDraft,
} from "../../../../../utils";
import Heading from "../components/Heading";
import SubHeading from "../components/SubHeading";
import Loader from "../../../../../components/Loader";
//import {checkPendingJobs} from "./SignIn"
const PhoneNumber = ({
  userId,
  setMobileSignUpStepNumber,
  jobIdFromUrl,
  techIdFromUrl,
  user
}) => {
  const location = useLocation();
  const firstFreePromoCode = localStorage.getItem("firstFreePromoCode");

  const queryParams = new URLSearchParams(location.search);
  const [inProgressMessage, setInProgressMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const { updateJob } = useJob();
  const [scheduleMsg, setScheduleMsg] = useState(false);
  const [jobIdDecline, setJobIdDecline] = useState(false);
  const [createJobStatus, setCreateJobStatus] = useState(false);
  const [customerConfirm, setCustomerConfirm] = useState(false);
  const [showPreviousTechLoader, setShowPreviousTechLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pendingJobModal, setPendingJobModal] = useState(false);
  const [lastPendingSoftware, setLastPendingSoftware] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [jobInfo, setJobInfo] = useState();
  const [userInfo, setUserInfo] = useState();
  const [disableBackToDashbord, setDisableBackToDashbord] = useState(false);
  const [customerHavePhoneNumber, setCustomerHavePhoneNumber] = useState(false);
  const [latestPendingJobToUpdate, setLatestPendingJobToUpdate] = useState();
  const [technicianData, setTechnicianData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  let inviteCodeFromLocal = localStorage.getItem("inviteCode");
  const [inviteCode, setInviteCode] = useState(
    inviteCodeFromLocal ? inviteCodeFromLocal : "nothing"
  );
  const InprogressJobMsg = pendingJobModal ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      {inProgressMessage}
    </span>
  ) : (
    <span></span>
  );
  const message = scheduleMsg ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      One of your previous job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b> is already
      scheduled with a technician. Are you sure you want to create a new job
      post?if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  ) : (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      We are still looking for a technician for your existing job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b>. Are you sure
      you want to create a new job post? if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  );
  const userIdFromUrl = queryParams.get("userId")
    ? queryParams.get("userId")
    : false;
  const [showPreviousGeekDropdown, setShowPreviousGeekDropdown] =
    useState(true);
  const [techniciansList, setTechniciansList] = useState([]);
  const [selectedPrevGeek, setSelectedPrevGeek] = useState();

  useEffect(() => {
    (async () => {
      if (jobIdFromUrl) {
        const jobData = await JobApi.retrieveJob(jobIdFromUrl);
        if (jobData) {
          // console.log("looking for jobData", {
          //   status: jobData.status,
          //   jobData,
          // });

          // Checking if job status is draft or not
          if (jobData.status !== JOB_STATUS.DRAFT) {
            window.location.href = "/";
          }

          setJobInfo(jobData);
          if (jobData.customer.user.id) {
            const userData = await UserApi.getUserById(
              jobData.customer.user.id
            );
            if (userData) {
              // console.log("looking for userData", { userData });
              if (
                userData.customer.phoneNumber &&
                userData.customer.phoneNumber !== ""
              ) {
                setCustomerHavePhoneNumber(true);
                setShowLoader(false);
              } else {
                setShowLoader(false);
              }
              setUserInfo(userData);
            }
          }
        }
      }

      if (userIdFromUrl) {
        const userData = await UserApi.getUserById(userIdFromUrl);
        if (userData) {
          // console.log("looking for userData", { userData });
          if (
            userData.customer.phoneNumber &&
            userData.customer.phoneNumber !== ""
          ) {
            setCustomerHavePhoneNumber(true);
            setShowLoader(false);
          } else {
            setShowLoader(false);
          }
          setUserInfo(userData);
        }
      }
      if (inviteCode && inviteCode != "nothing") setShowLoader(false);
      if (techIdFromUrl) setSelectedPrevGeek(techIdFromUrl);
    })();
  }, []);

  /**
   * This getLastSeenMessage is responsible to fetch technician details when this last seen.
   * @params : lastLogin 
   * @return : returns a relevant min,day,week
   * @author : Nafees
   **/
  function getLastSeenMessage(lastLogin) {
    if (!lastLogin) return "Last seen 1 month ago";
  
    const now = new Date();
    const loginTime = new Date(lastLogin);
    const diffInMs = now - loginTime;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60)); // Convert to minutes
    const diffInHours = Math.floor(diffInMinutes / 60); // Convert to hours
    const diffInDays = Math.floor(diffInHours / 24); // Convert to days
    const diffInWeeks = Math.floor(diffInDays / 7); // Convert to weeks
  
    if (diffInMinutes < 180) {
      return `Last seen ${diffInMinutes} minutes ago`; // Within 3 hours
    } else if (diffInHours < 8) {
      return `Last seen ${diffInHours} hours ago`; // Between 3 and 8 hours
    } else if (diffInHours < 24) {
      return "Last seen less than a day ago"; // Between 8 hours and 1 day
    } else if (diffInDays < 5) {
      return `Last seen ${diffInDays} days ago`; // Between 1 and 5 days
    } else if (diffInDays < 7) {
      return "Last seen less than a week ago"; // Between 5 and 7 days
    } else if (diffInWeeks < 6) {
      return `Last seen ${diffInWeeks} weeks ago`; // Between 1 and 6 weeks
    } else {
      return "Last seen more than a month ago"; // More than 6 weeks
    }
  }

  /**
   * This useEffect is responsible to fetch all the relevant techs when customer wish to select a specific tech from past.
   * @params : selectedTechs : Boolean
   * @return : returns a list of relevant techs(whether online/offline)
   * @author : Vinit
   **/
  useEffect(() => {
    (async () => {
      if (jobInfo && userInfo) {
        // console.log("My console for selected software id 11111111", {
        //   jobInfo,
        // });
        // console.log("My console for selected software id", {
        //   softwareId: jobInfo.software.id,
        // });
        // getting all online technician data from DB
        const allActiveTechnicians = await TechnicianApi.getOnlineTechnicians({
          softwares: jobInfo.software.id,
        });
        // console.log("My console for active techs", allActiveTechnicians.data);
        // getting all customers job response from DB by there customer.id
        const allCustomerJobsRes = await JobApi.findAllJobsByParams({
          customer: userInfo.customer.id,
        });
        let temp = [{ techId: "Any", value: "Any", label: "Any Geek" }];
        const allTechniciansId = allCustomerJobsRes.jobs.data.map((item) => {
          if (
            item?.technician?.id !== undefined &&
            item?.technician?.id !== ""
          ) {
            let techId = item?.technician?.id;
            let value = item?.technician?.user?.id;
            let label = `${item?.technician?.user?.firstName} ${item?.technician?.user?.lastName}`;
            let status = item?.technician?.status;
            const lastLogin = item?.technician?.user?.lastLogin; 
            const lastSeen = getLastSeenMessage(lastLogin);
            let techBlocked = item?.technician?.user?.blocked;
            temp.push({ techId, value, label, status, techBlocked,lastSeen });
          }
        });
        // console.log("My console to check temp", temp);
        let filteredArr = temp.filter(
          (ele, index, arr) =>
            arr.findIndex((ele2) => ele2.value === ele.value) === index
        );
        // console.log("My console to check filteredArr", filteredArr);

        let finalArr = filteredArr.map((ele) => {
          // console.log(
          //   "My console to check ele",
          //   ele.techId,
          //   allActiveTechnicians.data
          // );
          allActiveTechnicians.data.forEach((item) => {
            // console.log("My console to look for ele", ele);
            if (!ele.online) {
              // console.log(
              //   "My console to check for condition",
              //   ele.techId === item.id
              // );
              if (ele.techId === item.id) {
                ele["online"] = true;
              } else {
                ele["online"] = false;
              }
            }
          });
          if (!ele.techBlocked) {
            return ele;
          }
        });
        finalArr = finalArr.filter((ele) => ele !== undefined);
        // console.log("My console to check ele 2", finalArr);
        setTechniciansList(finalArr);
        setTechnicianData(finalArr);
        setShowPreviousTechLoader(false);
      }
    })();
  }, [jobInfo, userInfo]);

  // useEffect(() => {
  //   // console.log("customerHavePhoneNumber", customerHavePhoneNumber);
  // }, [customerHavePhoneNumber]);

  useEffect(() => {
    (async () => {
      if (userId) {
         console.log("phone number page");
        const fetchedUserDetails = await UserApi.getUserById(userId);
        if (fetchedUserDetails) {
          // console.log("looking userData", { fetchedUserDetails });
          setUserInfo(fetchedUserDetails);
        }
      }
    })();
  }, [userId]);

  /**
   * Function will check card authorization before any job except first job, if successful then make the job live.
   * @params = job (Type:job Object)
   * @response : Will call findTechnician function so the job gets live
   * @author : Vinit
   */
  const cardPreAuthorization = async (updateJobData, card_info = false) => {
    // holdPaymentWhileSubscription variable check if there is any subscription purchased by customer owner and have subscription minutes to zero then we will charge to customer
    if (firstFreePromoCode) {
      updateJobData.cardPreAuthorization = true;
      updateJobData.payment_type = "subscription_only";
      updateJobData.isPartnerFirstJob = true;
      updateJobData.ownerId = userInfo?.ownerId ? userInfo?.ownerId : userInfo?.id;
      
       await JobApi.updateJob(jobIdFromUrl, updateJobData);
        window.location.href = `/customer/profile-setup?page=tech-search&jobId=${jobIdFromUrl}`
    }
    
    
    let holdPaymentWhileSubscription = false;
    if (jobInfo?.customer?.id) {
      holdPaymentWhileSubscription = await checkCustomerHaveSubscriptionMinutes(
        jobInfo?.customer
      );
    }
    // If customer have owner then we will update job with that id otherwise that's customer's user id will be set to that field
    const ownerId = userInfo?.ownerId ? userInfo?.ownerId : userInfo?.id;
    // console.log("holdPaymentWhileSubscription", holdPaymentWhileSubscription);
    if (!jobInfo.cardPreAuthorization && holdPaymentWhileSubscription) {
      const ownerStripeStatus = await checkOwnerStripeId(userInfo);
      // console.log("Stripe Id info", { ownerStripeStatus, ownerId });
      const stripeId =
        userInfo?.ownerId && ownerStripeStatus.success
          ? ownerStripeStatus.stripeId
          : jobInfo?.customer?.stripe_id;
      const preAuthorization = await CustomerApi.holdChargeFromCustomer({
        stripe_id: stripeId,
        liveUser: jobInfo?.customer?.customerType === "live" ? true : false,
        jobId: jobIdFromUrl,
      });
      if (preAuthorization.status === "Successful") {
        console.log("cardPreAuthorization logs to check");
        updateJobData.cardPreAuthorization = true;
        updateJobData.payment_type = "card_only";
        updateJobData.ownerId = ownerId;
        const updatedJob = await JobApi.updateJob(jobIdFromUrl, updateJobData);
        if (selectedPrevGeek) {
          window.location.href = `/customer/profile-setup?page=tech-search&jobId=${jobIdFromUrl}&technicianId=${selectedPrevGeek}`;
        } else {
          window.location.href = `/customer/profile-setup?page=tech-search&jobId=${jobIdFromUrl}`;
        }
      } else {
        setIsLoading(false);
        let message = preAuthorization?.message
          ? preAuthorization?.message
          : "Card authorization failed.";
        openNotificationWithIcon("error", "Error", message);
      }
    } else {
      if(!holdPaymentWhileSubscription){
        updateJobData.cardPreAuthorization = true;
        updateJobData.payment_type = "subscription_only";
        updateJobData.ownerId = ownerId;
        let jobData = await JobApi.updateJob(jobIdFromUrl, updateJobData);
        if (selectedPrevGeek) {
          window.location.href = `/customer/profile-setup?page=tech-search&jobId=${jobIdFromUrl}&technicianId=${selectedPrevGeek}`;
        } else {
          window.location.href = `/customer/profile-setup?page=tech-search&jobId=${jobIdFromUrl}`;
        }

      }else{
        window.location.href = "/"
      }
    }
  };
  const checkPendingJobs = async () => {
    console.log("checkPendingJobs")
    try {
    
   
      const latestpendingJobs = await JobApi.latestpendingJobs({
        customer: jobInfo.customer.id,
      });
      console.log("latestpendingJobs",latestpendingJobs)
      setLatestPendingJobToUpdate(latestpendingJobs);

        let pendingJobs = await checkPendingStatusOfASAP(jobInfo);
        console.log("pendingJobs",pendingJobs)
        // if (pendingJobs.schedule_accepted) {
        //   setScheduleMsg(true);
        // }
        const desiredStatuses = ["Pending", "Waiting"];
        let pendingJobsArray=(latestpendingJobs?.statusJobs?.filter(entry => desiredStatuses.includes(entry.status)))
        console.log("pending jobs ::", pendingJobsArray);
         
        setJobIdDecline(pendingJobsArray[0]?.jobs[0]?._id);
       // setJobIdDecline(latestpendingJobs.last_pending_job.id);
        if (pendingJobs.success) {
          setLastPendingSoftware(latestpendingJobs?.last_pending_job?.software?.name);
          if (
            pendingJobs.status === "Inprogress" ||
            pendingJobs.status === "Accepted"
          ) {
            setPendingJobModal(true);
            setInProgressMessage(
              <span
                className="div-font"
                style={{ fontSize: 20, paddingTop: "40px" }}
              >
                One of your job of{" "}
                <b style={{ fontWeight: "bold" }}>{pendingJobs.name}</b> is
                already{" "}
                <b style={{ fontWeight: "bold" }}>{pendingJobs.status}</b> with
                a technician. Please complete that job first then post a new
                job.
              </span>
            );
          } else {
            setCustomerConfirm(true);
          }
        } else {
          isStripeAvailable()
        }
      
    } catch (e) {
      console.log("Error in checkPendingJobs ", e);
    }
  };
  const isStripeAvailable =async()=>{
    const isStripeAvilable = await checkOwnerStripeId(userInfo);
    console.log("mobile sign up isStripeAvilable ::::");
    if (isStripeAvilable.success) {
      let card_info = { has_card_or_subscription: false };
      if (userInfo) {
        mixpanel.identify(userInfo.email);
        mixpanel.track("Customer - Click on Get help now from mobile");
      }
     
      let updateJobObject = {};
      updateJobObject.status = "Pending";
      updateJobObject.tech_search_start_at = new Date();
      updateJobObject.tech_search_time = "900000";
      updateJobObject.jobType = userInfo?.customer?.customerType
      if (selectedPrevGeek) {
        updateJobObject.tech_search_time = "300000";
        updateJobObject.post_again_reference_technician = selectedPrevGeek;
        updateJobObject.post_again_reference_job = jobIdFromUrl;
        updateJobObject.post_again = true;
      }

      if (user?.customer?.subscription?.vip_membership) {
        const isFirstJob = !user.customer.subscription.isFirstJobCompleted;
        const vipDiscount = isFirstJob ? GEEKER_MEMBER_FIRST_JOB : user.customer.subscription.discount;
        
        updateJobObject["has_vip_membership"] = true;
        updateJobObject["vip_discount"] = Number(vipDiscount);
      }
      if(user){
        await CustomerApi. handleGeekerMemberSubscription({
          user:user,
        });
  
      }
    
      // Life cycle tag
      let lifeCycleTag = JobTags.GET_HELP_NOW;
      await JobCycleApi.create(lifeCycleTag, false, userInfo.id);

      let lifeCycleTagHAVE_CARD = JobTags.HAVE_CARD;
      await JobCycleApi.create(
        lifeCycleTagHAVE_CARD,
        jobInfo.id,
        userInfo.id
      );

      // console.log("Mobile sign up :: finally posting job", {
      //   jobInfo,
      //   updateJobObject,
      // });
      //mixpanel code
      mixpanel.identify(userInfo.email);
      mixpanel.track("Customer - ASAP job posted from mobile");

      cardPreAuthorization(updateJobObject, card_info);
    } else {
      openNotificationWithIcon("info", "Info", `Please add a credit card`);
      window.location.href = "/";
    }
  }
  const handleScheduleForLaterClick = async () => {
    if (
      !customerHavePhoneNumber &&
      phoneNumber.length - dialCode.length - 1 !== 10
    ) {
      setIsLoading(false);
      openNotificationWithIcon(
        "info",
        "info",
        "Phone number must be of 10 digits (excluding country code)."
      );
      return;
    }

    if (jobIdFromUrl) {
      if (!customerHavePhoneNumber) {
        await CustomerApi.updateCustomer(
          typeof jobInfo.customer === "string"
            ? jobInfo.customer
            : jobInfo.customer.id,
          { phoneNumber }
        );
      }
    }
    // setMobileSignUpStepNumber(5)
    if (selectedPrevGeek) {
      window.location.href = `mobile?jobId=${jobIdFromUrl}&page=ScheduleJob&technicianId=${selectedPrevGeek}`;
    } else {
      window.location.href = `mobile?jobId=${jobIdFromUrl}&page=ScheduleJob`;
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (
      !customerHavePhoneNumber &&
      phoneNumber.length - dialCode.length - 1 !== 10
    ) {
      setIsLoading(false);
      openNotificationWithIcon(
        "info",
        "info",
        "Phone number must be of 10 digits (excluding country code)."
      );
      return;
    }

    // if (isMobile) {
    //     localStorage.setItem('mobile', 'true');
    // }

    if (jobIdFromUrl) {
      if (!customerHavePhoneNumber) {
        await CustomerApi.updateCustomer(
          typeof jobInfo.customer === "string"
            ? jobInfo.customer
            : jobInfo.customer.id,
          { phoneNumber }
        );
      }

      checkPendingJobs();
    
    } else {
      openNotificationWithIcon(
        "success",
        "Success",
        "Phone number has been saved"
      );
      await CustomerApi.updateCustomer(
        typeof userInfo.customer === "string"
          ? userInfo.customer
          : userInfo.customer.id,
        { phoneNumber }
      );
      await JobCycleApi.create(
        JobTags.CUSTOMER_GO_TO_DASHBOARD,
        false,
        userInfo.id
      );
      //mixpanel code
      mixpanel.identify(userInfo.email);
      mixpanel.track("Customer - Click Go To Dashboard btn");
      localStorage.removeItem("inviteCode");
      setTimeout(() => {
        window.location.href = "/";
      }, 200);
    }
  };

  /**
   * Following function is to handle change of phone number field in the form.
   * @author : Vinit
   */
  const handlePhoneNumber = (value, data) => {
    setPhoneNumber(`+${value}`);
    setDialCode(data.dialCode);
  };

  /**
   * Following function is to manipulate the UI for second dropdown options
   * @params : data : {value: "", label: "", online: Boolean}
   * @return : HTML for dropdown and options
   * @author : Vinit,Nafees
   **/
  const formatOptionLabel = ({ value, label, online, status, lastSeen }) => {
    // Call the calculateLastSeen function
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginLeft: "5px", width: "100%" }}>
          {label === "Any Geek" ? (
            label
          ) : status === "Busy" ? (
            <span>
              {label}
              <span style={{ color: "#ffc800" }}>{" (In Session)"}</span>
            </span>
          ) : online ? (
            <span>
              {label}
              <span style={{ color: "#7ed957" }}>{" (Online)"}</span>
            </span>
          ) : (
            <span>
              {label}
              <span style={{ color: "#a8a8a8" }}>{" (Offline)"}</span>
              <br />
              <span style={{ fontSize: "12px", color: "#888" }}>
                {lastSeen}
              </span>
            </span>
          )}
        </div>
      </div>
    );
  };
  const closePendingModal = () => {
    setCustomerConfirm(false);
    setPendingJobModal(false);
  };

  const backToDashBoardStatus = async () => {
    const updateJobData = {
      cardPreAuthorization: false,
      customer: jobInfo?.customer?.id,
      guestJob: false,
    };
    await updateJob(jobIdFromUrl, updateJobData);
    window.location.href = "/dashboard";
  };

  const push_to_job_summary = async () => {
    setCreateJobStatus(true);
    await updateJob(jobIdDecline, { status: "Declined" });
    isStripeAvailable()
   //checkCustomerHaveCard(loginUser, customerValid);
  };
  /**
   * Following function is to handle change for second dropdown i.e. technician list
   * @params : data : {value: "", label: ""}
   * @return : Set a state var i.e. selectedTechnician : Boolean
   * @author : Vinit
   **/
  const handleTechniciansList = (data) => {
    console.log("My console to check selected technician", data.value);
    if (data.value !== "Any") {
      setSelectedPrevGeek(data.value);
      setSelectedStatus(data);
    } else {
      setSelectedPrevGeek();
      setSelectedStatus(null);
    }
   
    setTechnicianData(null)
  };
  const backToDashBoard = async () => {
    setDisableBackToDashbord(true);
    const updateJobData = {
      cardPreAuthorization: false,
      customer: jobInfo?.customer?.id,
      guestJob: false,
    };
    await updateJob(jobIdFromUrl, updateJobData);
    window.location.href = "/dashboard";
  };
  if (showLoader) return <Loader height="200px" />;

  return (
    <div className="headingDiv pl-20-max-width-280 pr-20-max-width-280">
      <div className="d-flex flex-column heading-div">
        <Heading
          text={
            customerHavePhoneNumber
              ? "Post Your Job"
              : "Enter Your Phone Number"
          }
        />
        <SubHeading
          text={
            customerHavePhoneNumber
              ? "Post a job now or schedule it for later"
              : "Last step! Add your number so we can contact you when your Geek is available"
          }
        />
      </div>
      <Modal
        className="get-help-now-modal"
        closable={true}
        onCancel={closePendingModal}
        visible={pendingJobModal}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="mobile-dsn-button-css"
              onClick={backToDashBoardStatus}
              key="no"
            >
              <span></span>Back To Dashbord
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {InprogressJobMsg}
          </span>
        </div>
      </Modal>
      <Modal
        className="get-help-now-modal"
        closable={true}
        visible={customerConfirm}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="mobile-dsn-button-css"
              onClick={backToDashBoard}
              key="no"
              disabled={createJobStatus || disableBackToDashbord}
            >
              {disableBackToDashbord ? (
                <Spin size="small" />
              ) : (
                <span>Back To Dashbord</span>
              )}
            </Button>

            <Button
              id="confirm-create-new"
              className="mobile-dsn-button-css"
              onClick={push_to_job_summary}
              key="yes"
              disabled={createJobStatus || disableBackToDashbord}
            >
              {createJobStatus ? (
                <Spin size="small" />
              ) : (
                <span>Create New</span>
              )}
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {message}
          </span>
        </div>
      </Modal>
      <form onSubmit={submitForm} className="w-100percent">
        <div className="email-div">
          {/* {userInfo?.customer?.phoneNumber !== "" && <PhoneInput */}
          {!customerHavePhoneNumber ? (
            <PhoneInput
              countryCodeEditable={false}
              name={"phoneNumber"}
              country="us"
              onlyCountries={["in", "gr", "us", "ca", "gb"]}
              className={`p-0 inputDivClass`}
              dropdownClass="phoneInputDropdownClass"
              // inputClass="business-plan-phone-input country-code-textarea"
              inputClass="sign-up-phone-input"
              value={phoneNumber}
              onChange={handlePhoneNumber}
            />
          ) : (
            <div className="phoneNumberPreviousTechDiv w-100percent">
              {showPreviousGeekDropdown ? (
                techniciansList.length > 1 && !techIdFromUrl ? (
                  <div
                    className="previousTechTouchDiv"
                    onClick={() => setShowPreviousGeekDropdown(false)}
                  >
                    Select a previous technician.
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <div className="jobSummaryDropDownDiv w-100percent text-left">
                  {/* {(techniciansList.length === 0  */}
                  {showPreviousTechLoader ? (
                    <Spin className="job-summary-spinner" />
                  ) : (
                    <>
                      <div className="w-100percent text-left">
                        <span className="preTechText">
                          Previous Technicians
                        </span>
                      </div>
                      <Select
                        options={techniciansList}
                        className="jobSummaryDropDown"
                        isSearchable={false}
                        onChange={handleTechniciansList}
                        formatOptionLabel={formatOptionLabel}
                        defaultValue={techniciansList[0]}
                        // isDisabled={isFirsJob ? true : false}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          )}
          {selectedStatus && (
            <div className="d-flex justify-content-center margin-bottom-15">
              <div style={{ width: "100%", maxWidth: "600px" }}>
                <p style={{ marginTop: '10px',textAlign:'justify' }}>
                  {selectedStatus.label === "Any Geek" ? (
                    null
                  ) : selectedStatus.status === 'Busy' ? (
                    `Sorry ${selectedStatus.label.split(' ')[0]} is helping another customer right now. We will message them as soon as you post your job and will help you find another Geek if they don’t respond.`
                  ) : selectedStatus.online ? (
                    `Great news! ${selectedStatus.label.split(' ')[0]} is available! You should be connected within a few minutes after posting.`
                  ): (
                    `Sorry ${selectedStatus.label.split(' ')[0]} is not online right now. We will message them as soon as you post your job or help you find another Geek if they don’t respond.`
                  )}
                </p>
              </div>
            </div>
          )}
          {/* {technicianData && (
            <div className="d-flex justify-content-center margin-bottom-15">
              <div style={{ width: "100%", maxWidth: "600px" }}>
                {technicianData[0].status === "Busy" ? (
                  <span style={{  fontStyle: "italic", lineHeight: "1.2", fontWeight: "normal",fontSize:"medium" }}>
                    Sorry {technicianData[0].label.split(' ')[0]} is helping another customer right now. We will message them as soon as you post your job and will help you find another Geek if they don’t respond.
                  </span>
                ) : technicianData[0].online ? (
                  <span style={{  fontStyle: "italic", lineHeight: "1.2", fontWeight: "normal",fontSize:"medium" }}>
                    Great news! {technicianData[0].label.split(' ')[0]} is available! You should be connected within a few minutes after posting.
                  </span>
                ): (
                  <span style={{  fontStyle: "italic",lineHeight: "1.2", fontWeight: "normal",fontSize:"medium" }}>
                    Sorry {technicianData[0].label.split(' ')[0]} is not online right now. We will message them as soon as you post your job or help you find another Geek if they don’t respond.
                  </span>
                )}
              </div>
            </div>
          )} */}
          <div className="continueButtonDiv">
            <Button
              variant={"contained"}
              className={"continueButton"}
              onClick={(e) => submitForm(e)}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spin className="spinner spinner-pos" />
              ) : (
                <span className="font-nova">
                  {jobIdFromUrl ? "Get Help Now!" : "Go To Dashboard"}
                </span>
              )}
            </Button>
          </div>
          {jobIdFromUrl && (
            <div className="schedule-for-later-div">
              <ReactSVG src={calanderLogo} className="sch-job-calander-logo" />
              <span
                onClick={handleScheduleForLaterClick}
                className="sch-for-later-text font-nova"
              >
                Schedule for later
              </span>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default PhoneNumber;
