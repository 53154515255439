import React, { useState } from "react";
import { Modal } from "antd";
import BasicButton from "components/common/Button/BasicButton";
import ScheduleForLater from "../ScheduleForLater";
import { useJob } from "../../../../../context/jobContext";
import { openNotificationWithIcon } from "../../../../../utils";
import { klaviyoTrack } from "../../../../../api/typeService.api";
import mixpanel from "mixpanel-browser";
import { useNotifications } from "../../../../../context/notificationContext";
import { useSocket } from "../../../../../context/socketContext";
import * as JobApi from "../../../../../api/job.api";
import {
  getPrimaryTime,
  isWorkingHours,
  isTimeMatchingSchedule,
  checkAndHandleTimeCollision
} from "../../../../../utils/index";
import * as JobCycleApi from "../../../../../api/jobCycle.api";
import { JobTags } from "../../../../../constants";

const ScheduleForLaterModal = ({
  showScheduleForLaterModal,
  setShowScheduleForLaterModal,
  scheduleJobTime,
  setScheduleJobTime,
  keepForSearching,
  setKeepForSearching,
  user,
  jobInfo,
  job,
  isDashboardSide,
  setShowKeepSearchingModal,
  searchTimesUp,
}) => {
  const { updateJob } = useJob();
  const { createNotification, fetchNotifications } = useNotifications();
  const { socket } = useSocket();
  const [showSpinner, setShowSpinner] = useState(false);
  const [disableButtonClick, setDisableButtonClick] = useState(false);
  const [scheduleTimeInLocalTimezone, setScheduleTimeInLocalTimezone] = useState();
  const [isShabbatTime, setIsShabbatTime] = useState(false)
  // const handleConfirmButton = () => {
  //     setShowScheduleForLaterModal(false)
  // }

  /**
   * emit send-schedule-alerts socket and create / fetch notification customer notifications
   * @params : jobStats(Type:Object): Have job details
   * @returns : null
   * @author : Ridhima Dhir
   */
  const emitSocketCreateFetchNotification = async (jobStats) => {
    try {
      let timeToSend = getPrimaryTime(scheduleJobTime);
      console.log("send-schedule-alerts :::::::::::");
      //Notification for customer
      const notificationData = {
        user: user.id,
        job: jobStats.id,
        read: false,
        actionable: false,
        title:
          "We are finding a technician for you. We will inform you when we find the technician",
        type: "Scheduled Job",
      };
      // console.log("notificationData ::::::::", notificationData);
      await createNotification(notificationData);
      console.log("fetchNotifications() :: called emitSocketCreateFetchNotification function in ScheduleForLaterModal/index.ts file");
      await fetchNotifications({ user: user.id });

      // call send-schedule-alerts socket from backend.
      // It will find available techs and send alerts by sms/email/notification
      socket.emit("search-for-tech", {
        jobId: jobStats.id,
        customerTimezone: user.timezone,
        jobData: jobStats,
        primaryTime: timeToSend,
        phoneNumber: user.customer.phoneNumber,
        customerName: user.firstName,
        customerEmail: user.email,
        technicianId:
          job && job?.post_again_reference_technician
            ? job.post_again_reference_technician
            : false,
      });
    } catch (err) {
      mixpanel.identify(user.email);
      mixpanel.track("There is catch error while create/fetch notification", {
        jobStats: jobStats,
        errMessage: err.message,
      });
      console.log(
        "There is catch error while create/fetch notification  :::: " +
          err.message
      );
    }
  };

  const scheduleForLater = async (e) => {
    e.preventDefault();
    console.log('scheduleForLater >>>>>>', scheduleJobTime);
    const data = await JobApi.scheduleAcceptedJobs({
      customer: user.customer.id,
    });
    // console.log('scheduleJobTime api',data)
    const primaryScheduleTimes = data.primaryScheduleTimes;
    const dateObjects = primaryScheduleTimes.map(
      (dateString) => new Date(dateString)
    );

    // Format Date objects as 12-hour time with AM/PM
    const formattedDates = dateObjects.map((date) => {
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    });
    // console.log('formattedDates>>>>>', formattedDates)
    const isMatch = isTimeMatchingSchedule(scheduleJobTime, formattedDates);
    // console.log('match done', isMatch);
    // const isWithinWorkingHours = isWorkingHours(scheduleJobTime);

		// if (!isWithinWorkingHours) {
		// 	openNotificationWithIcon('error', 'Error', "Our techs are mostly available between 9am-9pm EST Mon-Fri. Please schedule a good time during these business hours. ");
		// 	return;
		// }
		if(isMatch) {
			openNotificationWithIcon('error', 'Error', "One of your job is already scheduled with same date and time. Please pick any other time to schedule new job");
			return;
		}
    let scheduleTimeNew = getPrimaryTime(scheduleJobTime);
    const latestpendingJobs = await JobApi.latestpendingJobs({ "customer": user.customer.id });
    console.log('latest pending pob ::', latestpendingJobs)
    const scheduleJobsArray = latestpendingJobs?.statusJobs?.find(entry => entry.status === 'Scheduled')?.jobs || [];
   // const existingJobs = scheduleJobsArray;

    const existingJobs = scheduleJobsArray;
  
    const newScheduledTime = new Date(scheduleTimeNew);

    // Check if there is an existing scheduled job that collides with the new time
   const hasCollisions= checkAndHandleTimeCollision(existingJobs,newScheduledTime)

    if (hasCollisions) {
      openNotificationWithIcon('error', 'Error', "One of your job is already scheduled with same date and time. Please pick any other time to schedule new job");
      return;
    } 
		setDisableButtonClick(true)
		setShowSpinner(true)
	    const scheduleJobData = {};
		let scheduleDetails = {
			'primaryTimeAvailable':true,
			'primaryTimeExpiredAt':null,
			'secondaryTimeAvailable':false,
			'secondaryTimeExpiredAt':null,
			'scheduleExpired':false,
			'scheduleExpiredAt':null
		}

    let partnerName = '';
    let partnerStatus = false;
    if (user && user?.partner) {
      partnerName = user?.partner;
      partnerStatus = true;
    }
        const klaviyoData = {
            email: user.email,
            event: 'Scheduled Job Created from tech searching page',
            properties: {
              $first_name: user.firstName,
              $last_name: user.lastName,
              $partnerName: partnerName,
              $partnerStatus: partnerStatus,
            },
        };
        await klaviyoTrack(klaviyoData);

    // console.log("job changed to schedule>>>>>>>>>>>>>>>>>>>>>>>>", job.id);

    const hourDifferenceFromNow = scheduleTimeNew - new Date().getTime();
    // 30 min
    if (hourDifferenceFromNow < 1800000) {
      openNotificationWithIcon(
        "error",
        "Error",
        "Please schedule a job at least 30 minutes from now."
      );
      setShowSpinner(false);
      setDisableButtonClick(false);
      return;
    }

    setScheduleJobTime((prevState) => ({
      ...prevState,
      date: scheduleTimeNew,
    }));

		scheduleJobData.primarySchedule = scheduleTimeNew;
		scheduleJobData.status = "Scheduled";
		scheduleJobData.keepSearching = false;
		scheduleJobData.scheduleDetails = scheduleDetails;
		scheduleJobData.message_send_count = 0;
        scheduleJobData.scheduleDetails.scheduleExpiredAt = new Date(scheduleTimeNew - 300000)
		// updateJob(job.id, {status:"Scheduled", primarySchedule:scheduleTimeNew})
		// updateJob(job.id, scheduleJobData)
		const updatedJob = await JobApi.updateJob(job.id,scheduleJobData)
		await JobCycleApi.create(JobTags.SCHEDULE_AFTER_SEARCH, job.id, user.id);
        await emitSocketCreateFetchNotification(updatedJob)
        setShowScheduleForLaterModal(false)
		setTimeout(() => {
			window.location.href = isDashboardSide ? '/dashboard' :'/dashboard?&scheduleJobId='+job.id;
		}, 2000);
	}
	
    const handleClickNo =()=>{
    	setShowScheduleForLaterModal(false) 
    	if(searchTimesUp) setShowKeepSearchingModal(true)
    }

    return(<div className="schedule-for-later-modal-outer-div">
        <Modal
            className="schedule-modal"
            footer={null}
            closable={false}
            visible={showScheduleForLaterModal} 
            maskStyle={{backgroundColor:"#DCE6EDCF"}}
            maskClosable={false}
            width={766}
			onCancel={()=>{setShowScheduleForLaterModal(false)
						  if(searchTimesUp) setShowKeepSearchingModal(true) //open up keep searching modal if times up
					  }}
			wrapClassName="vertical-center-modal"
        >
        <div className="d-flex flex-column justify-content-center align-items-center schedule-for-later-modal">
        <div className="mt-3 text-left text-wrap" style={{ fontSize: "16px", width: "100%" }}>
          {
            (user?.roles[0] !== "owner" && user?.roles[0] !== "admin") ? 
            <span>Please make sure to attend your scheduled meeting (note that once a schedule is accepted, your company may incur a small fee for no-shows or last-minute cancellations, so we can keep our technicians happy)</span> :
            <div style={{
              padding: '20px',
              border: '1px solid #ccc',
              borderRadius: '8px',
              backgroundColor: '#f9f9f9',
              maxWidth: '600px',
              margin: '0 auto',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
              <div style={{ textAlign: 'center' }}>
                <strong style={{ fontSize: '18px', color: '#333' }}>Off-Peak Schedule!</strong>
              </div>
              <span style={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#555',
                lineHeight: '1.6',
                display: 'block',
                marginTop: '10px',
              }}>
                You are welcome to schedule with us at any time, but it may take longer to get help outside of business hours.
                <br style={{ marginBottom: '10px' }} />
                Please note our $24.99 'no-show' fee as a courtesy to our technicians. You can change or cancel up to 2 hours before your appointment.
                If you have an emergency, feel free to contact customer care during business hours.
              </span>
            </div>
          }
				</div>
				<div className="schedule-for-later-modal-heading mt-3">Schedule for later</div>
				<ScheduleForLater
					setScheduleJobTime={setScheduleJobTime}
					scheduleJobTime={scheduleJobTime}
					setKeepForSearching={setKeepForSearching}
					keepForSearching={keepForSearching}
          job={job}
          user={user}
          scheduleTimeInLocalTimezone={scheduleTimeInLocalTimezone}
          setScheduleTimeInLocalTimezone={setScheduleTimeInLocalTimezone}
          setIsShabbatTime={setIsShabbatTime}
				/>
			</div>

        <div className="mb-75 mt-45 d-flex justify-content-around">
          <BasicButton
            onClick={handleClickNo}
            btnTitle={"No"}
            height={"60px"}
            width={"138px"}
            background={"rgb(151 171 182)"}
            color={"#fff"}
          />
          <BasicButton
            disable={disableButtonClick || isShabbatTime}
            onClick={scheduleForLater}
            btnTitle={"Yes"}
            height={"60px"}
            width={"138px"}
            background={"#01D4D5"}
            color={"#fff"}
            showSpinner={showSpinner}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ScheduleForLaterModal;
