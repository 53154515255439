import React from 'react';
import { Modal, Form, Checkbox, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import shabbatImage from '../../assets/images/shabbat.png';
import whatisShabbat from '../../assets/images/WhatisShabbat.jpg';
import shabbatMenu from '../../assets/images/ShabbatMenu.png';
import shabbatExplained from '../../assets/images/ShabbatExplained.png';
import learnMore from '../../assets/images/LearnMoreShabbat.png';

const ShabbatInfoModal = ({
  isVisible,
  onClose,
  showReminderSection = false,
  shabbatTimes = null,
  timeRemaining = '',
  onReminderSubmit,
  loading = false,
  images = {
    shabbatImage: shabbatImage,
    whatisShabbat: whatisShabbat,
    shabbatMenu: shabbatMenu,
    shabbatExplained: shabbatExplained,
    learnMore: learnMore,
  }
}) => {
  const [form] = Form.useForm();

  const modalTitle = shabbatTimes ? (
    <div className="modal-title">
      <span>
        Hi, We're in <strong>Shabbat Mode</strong> until{' '}
        {shabbatTimes.endTimeFormatted.day} {shabbatTimes.endTimeFormatted.time} EST
      </span>
    </div>
  ) : (
    <div className="modal-title">
      <span>Learn About Shabbat</span>
    </div>
  );

  return (
    <StyledModal
      title={modalTitle}
      visible={isVisible}
      onCancel={onClose}
      width={700}
      centered
      maskClosable={true}
      footer={null}
      closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
    >
      <ModalContent>
        <div className="quote">
          "Six days you shall labor and do all your work, but the seventh day is a sabbath for your God:
          you shall not do any work—you, your son or daughter, your male or female slave, or your cattle,
          or the stranger who is within your settlements". (Exodus 20:8-10)
        </div>

        <p>
          At Geeker.co, we believe in the universal value of taking a day each week to pause, recharge,
          reflect, and reconnect with what matters most. We take this day to disconnect from our work of
          creative output, and from our devices and spend this special time focusing on G-d, our family
          and ourselves.
        </p>

        <p>
          We believe that Observing the Shabbat is a testimony that G-d actively guides the entire world
          (including our finances) at every single moment.
        </p>

        <div className="image-container">
          <img src={images.shabbatImage} alt="Shabbat" />
        </div>

        {shabbatTimes && timeRemaining && (
          <p>
            Thank you for understanding. Please come back soon, we're looking forward to helping you and
            will be ready to assist you in <strong>{timeRemaining}</strong>
          </p>
        )}

        {showReminderSection && (
          <div className="reminder-section">
            <Form form={form} onFinish={onReminderSubmit}>
              <ReminderCheckboxes>
                <div>Would you like us to notify you when we're back?</div>
                <Form.Item name="notifications">
                  <Checkbox.Group>
                    <Checkbox value="email">Email me</Checkbox>
                    <Checkbox value="sms">Send me a text</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="remind-button"
                  loading={loading}
                >
                  Remind me
                </Button>
              </ReminderCheckboxes>
            </Form>
          </div>
        )}

        <VideoLinksSection>
          <div className="links-header">Want to know more?</div>
          <div className="resources-grid">
            <ResourceCard href="https://www.chabad.org/library/article_cdo/aid/633659/jewish/What-Is-Shabbat.htm">
              <img src={images.whatisShabbat} alt="What is Shabbat?" />
              <span>What is Shabbat?</span>
            </ResourceCard>
            <ResourceCard href="https://www.youtube.com/watch?v=s01ZO13Jq5M">
              <img src={images.shabbatMenu} alt="Shabbat Menu" />
              <span>Shabbat Menu</span>
            </ResourceCard>
            <ResourceCard href="https://www.youtube.com/watch?v=YG0lbfGUpwU">
              <img src={images.shabbatExplained} alt="Shabbat Explained" />
              <span>Shabbat Explained</span>
            </ResourceCard>
            <ResourceCard href="https://www.youtube.com/watch?v=FVho7sP1t3Y">
              <img src={images.learnMore} alt="Learn More" />
              <span>Learn More About Shabbat</span>
            </ResourceCard>
          </div>
        </VideoLinksSection>
      </ModalContent>
    </StyledModal>
  );
};

const ModalContent = styled.div`
  .quote {
    font-style: italic;
    padding: 16px;
    background: #f8f9fa;
    border-left: 4px solid #15b1b0;
    margin: 16px 0;
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    
    img {
      width: 70%;
      border-radius: 8px;
    }
  }

  .reminder-section {
    margin-top: 24px;
    padding: 16px;
    background: #fafafa;
    border-radius: 8px;
  }
`;

const VideoLinksSection = styled.div`
  margin-top: 24px;
  
  .links-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .resources-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
    margin-top: 16px;
  }
`;

const ResourceCard = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 12px;
  border-radius: 8px;
  transition: all 0.2s ease;
  
  &:hover {
    background: #f5f5f5;
    transform: translateY(-2px);
  }
  
  img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  
  span {
    color: #15b1b0;
    font-weight: 500;
    text-align: center;
  }
`;

const ReminderCheckboxes = styled.div`
  .ant-form-item {
    margin-bottom: 12px;
  }
  
  .ant-checkbox-wrapper {
    margin-right: 24px;
  }

  .remind-button {
    background: #15b1b0;
    border: none;
    height: 45px;
    
    &:hover {
      background: #006565;
    }
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
  }
  
  .ant-modal-header {
    padding: 16px 24px;
    border-bottom: none;
    background: #15b1b0;
    color: white;
  }
  
  .modal-title {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 19px;
    font-weight: 700;
    color: white;
  }

  .ant-modal-title {
    color: white;
  }
  
  .ant-modal-body {
    padding: 24px;
  }
`;

export default ShabbatInfoModal;
