import React, { lazy, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import commonStyles from "../../../../css.module/common.module.css";
import { handleSoftware, handleSoftwareWithUser } from "../../../JobFlow";
import { useUser } from "../../../../../context/useContext";
import { useLocation } from "react-router";
import { selectCategory, selectSoftware, updateDescription, updateProblem } from "stores/devices/mobile/Job/Slice";
import { OtherNotSure,FIRST_JOBPOP,PARTNER_CC_SKIP_PROMO } from "../../../../../constants";
import { useJob } from "../../../../../context/jobContext";
import {  Modal } from "antd";
import { Button } from "react-bootstrap";
import * as PromocodeApi from "../../../../../api/promoCode.api";
import { getPartnerWithShortName } from "../../../../../api/partner.api";
import { isMobile } from "react-device-detect";
import { useShabbat } from "context/shabbatContext";
import { openNotificationWithIcon } from "utils";


const DefaultButton = lazy(() => import("../../../components/Buttons/Default"));

export default function StepOne() {
  const location = useLocation();
  const {getTotalJobs } = useJob();
  const queryParams = new URLSearchParams(location.search);
  const technicianUserId = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : false;
  const jobIdFromUrl = queryParams.get("jobId")
    ? queryParams.get("jobId")
    : false;
  const repost = queryParams.get("repost") ? queryParams.get("repost") : false;
  const MobileJobSlice = useSelector((state) => state.JobSlice);
  const history = useHistory();
  const { user } = useUser();
  const dispatch = useDispatch();
  const [fistJobPopup, setFistJobPop] = useState(false);
  const { isShabbat } = useShabbat();


  // useEffect(() => {
  //   // console.log("Debugging user", { user });
  // }, [user]);
  useEffect(() => {
    const firstJobPartner = async () => {
      const promoCodeName = localStorage.getItem('partnerPromoCode');
      const partnerName = localStorage.getItem('partner');
      
      if (promoCodeName && partnerName && user) {
        const response = await PromocodeApi.retrievePromoData(promoCodeName);
  
        if (response && response.used_by && response.promocode_status !== "expired") {
          if (user.id) {
            const findUser = response.used_by.find(
              (item) => item.user_id === user.id
            );
  
            if (findUser) {
              setFistJobPop(false); // Promo code already used, so don't show the modal
            } else {
              setFistJobPop(true); // Promo code not used, show the modal
            }
          }
        }
      }
  
      if (!user && partnerName && promoCodeName) {
        setFistJobPop(true);
      }
    };
  
    firstJobPartner();
  }, [user, setFistJobPop]);

  useEffect(() => {
    (async () => {
      if (user) {
        const promoCodeName = localStorage.getItem('partnerPromoCode');
        if (!promoCodeName) {
          const partnerName = localStorage.getItem('partner');
          if (partnerName) {
            const partnerData = await getPartnerWithShortName(partnerName);
            const promoData  = await PromocodeApi.retrievePromoData(partnerData?.data?.promoCode);
            console.log('promoData', partnerData, promoData)
            if (promoData && promoData.used_by && promoData.promocode_status !== "expired") {
              if (user.id) {
                const findUser = promoData.used_by.find(
                  (item) => item.user_id === user.id
                );

                if (findUser) {
                  setFistJobPop(false); // Promo code already used, so don't show the modal
                } else {
                  setFistJobPop(true); // Promo code not used, show the modal
                  if(promoData?.promo_code == PARTNER_CC_SKIP_PROMO){
                    if(isMobile){
                      localStorage.setItem("firstFreePromoCode", promoData?.promo_code);   
                    }
                    }
                }

              }

            }
          }
        }
      }

    })()
  }, [user])

  const handleChangeSlice = async (SliceName, action) => {
    await dispatch(SliceName(action));
  };

  const handleIAmNotSureLinkClick = async () => {

    let itSpecialistData;

    for (let i = 0; i < MobileJobSlice.SoftwareList.length; i++) {
      if (MobileJobSlice.SoftwareList[i].id === OtherNotSure) {
        itSpecialistData = MobileJobSlice.SoftwareList[i];
        break;
      }
    }

    await handleChangeSlice(selectCategory, itSpecialistData.category);

    await handleChangeSlice(selectSoftware, itSpecialistData);

    const lastIndex = itSpecialistData.sub_option.length - 1;

    await handleChangeSlice(updateProblem, itSpecialistData.sub_option[lastIndex]);

    await handleChangeSlice(updateDescription, "");

    if (user) {
      history.push(`/issue-description`);
    } else {
      history.push(`/select-description`);
    }

  };

  const handleButtonClick = (item) => {
    if (user) {
      handleSoftwareWithUser(
        item.id,
        history,
        technicianUserId,
        jobIdFromUrl,
        repost
      );
    } else {
      handleSoftware(item.id, history, technicianUserId);
    }
  };

  return (
    <>
     <Modal
      visible={fistJobPopup}
      
      footer={[
        <Button 
          key="submit" 
          type="primary" 
          className="btn app-btn job-accept-btn modal-footer-btn" 
          onClick={()=>setFistJobPop(false)}
        >
          OK
        </Button>,
      ]}
      className="responsive-modal"
      closable={false}
    >
      <p className="modal-text">
        You've got a free job! Just fill out your info and we will have a technician helping you in minutes.
      </p>
    </Modal>
      <div className={commonStyles.box}>
        <div className={commonStyles.title_group}>
          <span className={commonStyles.h2_title}>
            Let's get your problem solved!
          </span>
          <span className={commonStyles.paragraph_subtitle}>
            Tell us which issue you're experiencing
          </span>
        </div>
        <div className={commonStyles.boxes}>
          {MobileJobSlice && MobileJobSlice.CategoryList
            ? MobileJobSlice.CategoryList.map((item, index) => {
                return (
                  <DefaultButton
                    className={commonStyles.category_button}
                    key={index}
                    onClick={() => {isShabbat ? openNotificationWithIcon('error', 'Shabbat', 'We are closed on Shabbat') : handleButtonClick(item)}}
                    title={item.name}
                    subtitle={item.description}
                    showicons
                    group={item.id}
                  />
                );
              })
            : null}
          <div className={commonStyles.border_bottom}>
            <span className={commonStyles.link}>
              <div onClick={ isShabbat ? ()=>openNotificationWithIcon('error', 'Shabbat', 'We are closed on Shabbat') : handleIAmNotSureLinkClick} >
                <span className={commonStyles.link_frame}>I'm not sure</span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
